<template>
<!-- NOTE: IMPORTANT: -->
<!-- Any changes made to this file's html/vue needs to be made in 2 different places -->
<!-- because there is an "v-if" and "v-else" used here that both use the same content but it is a workaround -->
<!-- to handle both the legacy code implementation as well as the proper design system implementation -->
<!-- please do not miss doing this in both places, if you don't, then 1 place will not reflect the changes -->
<!-- NOTE: IMPORTANT: -->
    <div v-if="isLoadingFlag">
        <hb-modal
            v-if="modal"
            v-model="modalDialog"
            :size="state === 'view' && $vuetify.breakpoint.xs ? 'fullscreen' : 'large'"
            @click="closeDialogue"
            show-help-link
        >
            <template v-slot:title>
                <hb-btn
                    v-if="adjustmentMode"
                    icon
                    tooltip="Back"
                    @click="closeEdit"
                    mdi-code="mdi-chevron-left"
                    active-state-off
                    :color="state === 'view' ? '#101318' : ''"
                    :hover-background-color="modal ? '#CAEAEA' : ''"
                >
                </hb-btn>
                <span class="ml-1" :class="{'display-invoice-mobile-title': $vuetify.breakpoint.xs}">
                    {{state === 'edit' ? 'Edit Invoice' : `${adjustmentMode ? 'Adjustment ':''}Invoice #${invoice.number} ${invoice.Lease && invoice.Lease.Unit ? `(${invoice.Lease.Unit.number} - ${invoice.Lease.Unit.Address.city} - ${invoice.Lease.Unit.Address.address})` : ''}`}} 
                </span>
            </template>
            <template v-slot:content>
                <status class="pa-2" @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
                <status class="pa-2" @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>

                    <view-invoice v-if="state == 'view'" :canAdjust=canVoidAdjust :selected="invoice" :isAdjusted="adjustmentMode" @refreshInvoice="saveAdjustedInvoice" @open-void-modal="openVoidModal" ref="viewInvoice"></view-invoice>
                <create-invoice v-if="state == 'edit'" :selected="invoice" @cancel="closeEdit" :isAdjusted="adjustmentMode"></create-invoice>
                <create-invoice v-if="state == 'create'"></create-invoice>
            </template>
            <template v-slot:right-actions>
                <template v-if="adjustmentMode">
                    <hb-btn color="primary" @click="saveInvoiceAdjustment">Save</hb-btn>
                </template>
                <template v-else>
                    <div :class="{'view-invoice-dialog': state === 'view' && $vuetify.breakpoint.xs}">
                            <template v-if="!$vuetify.breakpoint.xs && (is_auction_invoice || is_inter_property_invoice || isFreeInsuranceInvoice || has_adjustment_payment) && (invoice.status != -1)">
                            <hb-tooltip>
                                <template v-slot:item>
                                    <hb-btn color="secondary" @click="voidInvoice(invoice)" v-if="state !== 'create' && (invoice.status != -1) && isAdmin" :disabled="canVoidAdjust">Void</hb-btn>
                                </template>
                                <template v-slot:body>
                                    {{ invoiceType }} Invoice cannot be edited
                                </template>
                            </hb-tooltip>
                        </template>
                        <template v-else>
                            <hb-btn color="secondary" @click="voidInvoice(invoice)" v-if="!$vuetify.breakpoint.xs && state !== 'create' && (invoice.status != -1) && isAdmin" :disabled="canVoidAdjust">Void</hb-btn>
                        </template>

                        <template v-if="!$vuetify.breakpoint.xs && disableReissue && invoice.status == -1 && invoice.Lease.end_date">
                            <hb-tooltip>
                                <template v-slot:item>
                                    <hb-btn color="secondary" class="ml-2 mr-2" @click="reissueInvoice(invoice)" v-if="reissueButton" :disabled="disableReissue">Reissue</hb-btn>
                                </template>
                                <template v-slot:body>
                                    Reissue can not be performed over close lease
                                </template>
                            </hb-tooltip>
                        </template>
                        <template v-else>
                            <hb-btn color="secondary" class="ml-2 mr-2" @click="reissueInvoice(invoice)" v-if="!$vuetify.breakpoint.xs && reissueButton" :disabled="disableReissue">Reissue</hb-btn>
                        </template>
                        
                            <template v-if="!$vuetify.breakpoint.xs && (is_auction_invoice || is_inter_property_invoice || isFreeInsuranceInvoice || has_adjustment_payment) && (invoice.status != -1)">
                            <hb-tooltip>
                                <template v-slot:item>
                                    <hb-btn color="secondary" @click="adjustInvoice" v-if="invoice && (invoice.status != -1) && isAdmin && state !== 'create'" :disabled="canVoidAdjust">Adjust</hb-btn>
                                </template>
                                <template v-slot:body>
                                        {{ invoiceType }} Invoice cannot be edited
                                </template>
                            </hb-tooltip>
                        </template>
                        <template v-else>
                            <hb-btn color="secondary" @click="adjustInvoice" v-if="!$vuetify.breakpoint.xs && isAdjustEnabled && invoice && (invoice.status != -1) && isAdmin && state !== 'create'" :disabled="canVoidAdjust">Adjust</hb-btn>
                        </template>
                        <!-- <hb-btn color="secondary">Email</hb-btn> -->
                        <hb-btn v-if="!$vuetify.breakpoint.xs && showYieldButton" color="secondary" @click="showRetainedRevenueModal">Yield</hb-btn>
                        <hb-btn v-if="!$vuetify.breakpoint.xs" color="secondary" @click="download(invoice.id)"  :loading="isLoading($options.name)">Print / Download</hb-btn>
                        <hb-btn v-if="!national_account" color="secondary" @click="sendInvoiceModal = true,$refs?.sendInvoice?.fetchDataOnEvent()">Send Invoice</hb-btn>
                        <!-- <hb-btn color="secondary" @click="showWebView">Print</hb-btn> -->
                        <hb-menu v-if="$vuetify.breakpoint.xs" options>
                            <v-list>
                                <v-list-item :class="{'border-btn-none': state === 'view' && $vuetify.breakpoint.xs}">
                                    <template v-if="(is_auction_invoice || is_inter_property_invoice || isFreeInsuranceInvoice || has_adjustment_payment) && (invoice.status != -1)">
                                        <hb-tooltip>
                                            <template v-slot:item>
                                                <hb-btn color="secondary" @click="voidInvoice(invoice)" v-if="state !== 'create' && (invoice.status != -1) && isAdmin" :disabled="canVoidAdjust">Void</hb-btn>
                                            </template>
                                            <template v-slot:body>
                                                {{ invoiceType }} Invoice cannot be edited
                                            </template>
                                        </hb-tooltip>
                                    </template>
                                    <template v-else>
                                        <hb-btn color="secondary" @click="voidInvoice(invoice)" v-if="state !== 'create' && (invoice.status != -1) && isAdmin" :disabled="canVoidAdjust">Void</hb-btn>
                                    </template>
                                </v-list-item>
                                <v-list-item :class="{'border-btn-none': state === 'view' && $vuetify.breakpoint.xs}" v-if="reissueButton">
                                    <template v-if="disableReissue && invoice.status == -1 && invoice.Lease.end_date">
                                        <hb-tooltip>
                                            <template v-slot:item>
                                                <hb-btn color="secondary" class="ml-2 mr-2" @click="reissueInvoice(invoice)" v-if="reissueButton" :disabled="disableReissue">Reissue</hb-btn>
                                            </template>
                                            <template v-slot:body>
                                                Reissue can not be performed over close lease
                                            </template>
                                        </hb-tooltip>
                                    </template>
                                    <template v-else>
                                        <hb-btn color="secondary" class="ml-2 mr-2" @click="reissueInvoice(invoice)" v-if="reissueButton" :disabled="disableReissue">Reissue</hb-btn>
                                    </template>
                                </v-list-item>
                                <v-list-item :class="{'border-btn-none': state === 'view' && $vuetify.breakpoint.xs}">
                                    <template v-if="(is_auction_invoice || is_inter_property_invoice || isFreeInsuranceInvoice || has_adjustment_payment) && (invoice.status != -1)">
                                        <hb-tooltip>
                                            <template v-slot:item>
                                                <hb-btn color="secondary" @click="adjustInvoice" v-if="invoice && (invoice.status != -1) && isAdmin && state !== 'create'" :disabled="canVoidAdjust">Adjust</hb-btn>
                                            </template>
                                            <template v-slot:body>
                                                {{ invoiceType }} Invoice cannot be edited
                                            </template>
                                        </hb-tooltip>
                                    </template>
                                    <template v-else>
                                        <hb-btn color="secondary" @click="adjustInvoice" v-if="invoice && (invoice.status != -1) && isAdmin && state !== 'create'" :disabled="canVoidAdjust">Adjust</hb-btn>
                                    </template>
                                </v-list-item>
                                <v-list-item :class="{'border-btn-none': state === 'view' && $vuetify.breakpoint.xs}" v-if="showYieldButton" :ripple="false">
                                    <hb-btn color="secondary" @click="showRetainedRevenueModal">Yield</hb-btn>
                                </v-list-item>

                                <v-list-item :class="{'border-btn-none': state === 'view' && $vuetify.breakpoint.xs}" :ripple="false">
                                    <hb-btn color="secondary" @click="download(invoice.id)"  :loading="isLoading($options.name)">Print / Download</hb-btn>    
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </div>
                </template>
            </template>
        </hb-modal>
        <v-card v-else>
            <v-card-title class="d-flex align-center ma-0 pa-0 display-invoice-header-title-wrapper">
                <v-row no-gutters class="pl-4 pr-2">
                    <v-col cols="10" class="d-flex align-center">
                        <hb-btn
                            v-if="adjustmentMode"
                            icon
                            tooltip="Back"
                            @click="closeEdit"
                            mdi-code="mdi-chevron-left"
                            active-state-off
                            :color="state === 'view' ? '#101318' : ''"
                            :hover-background-color="modal ? '#CAEAEA' : ''"
                        >
                        </hb-btn>
                        <span class="font-weight-medium" :class="{ 'ml-1' : adjustmentMode }">
                            {{state === 'edit' ? 'Edit Invoice' : `${adjustmentMode ? 'Adjustment ':''}Invoice #${invoice.number} ${invoice.Lease && invoice.Lease.Unit ? `(${invoice.Lease.Unit.number} - ${invoice.Lease.Unit.Address && this.$options.filters.formatAddress(invoice.Lease.Unit.Address, '-', true)})` : ''}`}} 
                        </span>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end">
                        <hb-btn icon tooltip="Close" @click="closeDialogue" active-state-off hover-background-color="#CAEAEA">mdi-close</hb-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-0 ma-0 hb-default-font-size">
                <span class="hb-text-night hb-default-font-size">
                    <status class="pa-2" @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
                    <status class="pa-2" @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>

                        <view-invoice v-if="state == 'view'" :canAdjust=canVoidAdjust :selected="invoice" :isAdjusted="adjustmentMode" @refreshInvoice="saveAdjustedInvoice" ref="viewInvoice" :fromMasterVueFile="fromMasterVueFile"></view-invoice>
                    <create-invoice v-if="state == 'edit'" :selected="invoice" @cancel="closeEdit" :isAdjusted="adjustmentMode"></create-invoice>
                    <create-invoice v-if="state == 'create'"></create-invoice>
                </span>
                <hb-bottom-action-bar @close="closeDialogue" border-bottom-radius>
                    <template v-slot:right-actions>
                        <template v-if="adjustmentMode">
                            <hb-btn color="primary" @click="saveInvoiceAdjustment">Save</hb-btn>
                        </template>
                        <template v-else>
                            <template v-if="(is_auction_invoice || is_inter_property_invoice || isFreeInsuranceInvoice || has_adjustment_payment) && (invoice.status != -1)">
                                <hb-tooltip>
                                    <template v-slot:item>
                                        <hb-btn color="secondary" @click="voidInvoice(invoice)" v-if="state !== 'create' && (invoice.status != -1) && isAdmin" :disabled="canVoidAdjust">Void</hb-btn>
                                    </template>
                                    <template v-slot:body>
                                        {{ invoiceType }} Invoice cannot be edited
                                    </template>
                                </hb-tooltip>
                            </template>
                            <template v-else>
                                <hb-btn color="secondary" @click="voidInvoice(invoice)" v-if="state !== 'create' && (invoice.status != -1) && isAdmin" :disabled="canVoidAdjust">Void</hb-btn>
                            </template>

                            <hb-btn color="secondary" @click="reissueInvoice(invoice)" v-if="state !== 'create' && (invoice.status == -1) && isAdmin" :disabled="!invoice.can_reissue">Reissue</hb-btn>
                            <template v-if="(is_auction_invoice || is_inter_property_invoice || isFreeInsuranceInvoice || has_adjustment_payment) && (invoice.status != -1)">

                                <hb-tooltip>
                                    <template v-slot:item>
                                        <hb-btn color="secondary" @click="adjustInvoice" v-if="invoice && (invoice.status != -1) && isAdmin && state !== 'create'" :disabled="canVoidAdjust">Adjust</hb-btn>
                                    </template>
                                    <template v-slot:body>
                                        {{ invoiceType }} Invoice cannot be edited
                                    </template>
                                </hb-tooltip>
                            </template>
                            <template v-else>
                                <hb-btn color="secondary" @click="adjustInvoice" v-if="isAdjustEnabled && invoice && (invoice.status != -1) && isAdmin && state !== 'create'" :disabled="canVoidAdjust">Adjust</hb-btn>
                            </template>
                            <!-- <hb-btn color="secondary">Email</hb-btn> -->
                            <hb-btn v-if="showYieldButton" color="secondary" @click="showRetainedRevenueModal">Yield</hb-btn>
                            <hb-btn color="secondary" @click="download(invoice.id)"  :loading="isLoading($options.name)">Print / Download</hb-btn>
                            <!-- <hb-btn color="secondary" @click="showWebView">Print</hb-btn> -->
                        </template>
                    </template>
                </hb-bottom-action-bar>
            </v-card-text>
        </v-card>
        <reversal
            v-model="refundFlag"
            v-if="showDirectRefundModal"
            :isRefund="this.isRefund"
            :isVoid="false"
            :isPartialRefund="isPaymentRefunded && !isFullRefund"
            :paymentMethod="this.is_inter_property_payment ? this.payment.InterPropertyPayment.sourcePayment.method : this.payment.method"
            @close="resetValidation"
            :payments="this.is_inter_property_payment ? this.payment.InterPropertyPayment.appliedPayments.filter(item => item.payment_id === this.payment.id && item.invoice_id === this.invoice.id) : this.payment.AppliedPayments.filter(item => item.invoice_id === this.invoice.id)"
            :is_fatzebra_payment="this.is_fatzebra_payment"
            :selectedInvoices.sync="this.selectedInvoices"
            :payment="this.payment"
            :refundOption="refund_option"
            :property_id="this.payment.property_id"
            :is_credit_invoice_line="is_credit_invoice_line"
            :refundAmount="refundAmount"
        />
        <reversal-confirmation 
            v-model="reversalConfirmation"
            v-if="reversalConfirmation"
            :isRefund="this.isRefund"
            @close="reversalConfirmation = false"
            :payment="payment"
            :void_data="void_data"
            :credit_data="credit_data"
            :reversal_meta="reversal"
            :is_credit_invoice_line="is_credit_invoice_line"
        />
        <hb-modal
            confirmation
            footerOff
            v-model="sendInvoiceModal"
            size="medium"
            title="Send Invoice"
            title-icon="mdi-user-actions-custom-1"
            @close="$refs.sendInvoice.closeModal()"
            >
            <template v-slot:content>
                <send-invoice :payment_id="invoice_id" ref="sendInvoice"  @close="sendInvoiceModal = false"></send-invoice>
            </template>
        </hb-modal>

        <hb-modal 
            v-model="showAdjustmentConfirmation"
            size="medium"
            title="Confirmation"
            confirmation
            show-help-link
        >

            <template v-slot:content>
                <div class="pa-4">
                    <span class="hb-text-night">Adjusting an invoice will void the current, and create a new invoice with edits made. </span><br>
                    <div class="mt-4">
                        <span class="hb-text-night font-weight-medium">Are you sure you want to continue?</span>
                    </div>
                </div>
            </template>

            <template v-slot:right-actions>
                <hb-btn color="primary" @click="showAdjustmentMode" :disabled="isLoading($options.name)" :loading="isLoading($options.name)">Confirm</hb-btn>
            </template> 
        </hb-modal>

        <hb-modal 
            v-model="adjustmentConfirmationIfNoPayment"
            size="medium"
            title="Confirmation"
            confirmation
            show-help-link
        >

            <template v-slot:content>
                <div class="pa-4">
                    <span class="hb-text-night">Adjusting an invoice will void the current, and create a new invoice with edits made. </span><br>
                    <div class="mt-4">
                        <span class="hb-text-night font-weight-medium">Are you sure you want to continue?</span>
                    </div>
                </div>
            </template>

            <template v-slot:right-actions>
                <hb-btn color="primary" class="ml-2" @click="saveAdjustedInvoice(false)" :disabled="isLoading($options.name)" :loading="isLoading($options.name)">Confirm</hb-btn>
            </template> 
        </hb-modal>

        <!-- <hb-modal 
            v-model="showUnAppliedPaymentsUtilizationOptions"
            size="medium"
            title="Warning"
            confirmation
        >
            <template v-slot:subheader>
                <span class="hb-text-night">
                    By proceeding you will unapply all payments made after {{earliestPaymentDate | formatDateServices}}. These funds will be placed in the tenant’s Reserve and/or Credit Balance. 
                </span><br>
                <div class="mt-4">
                    <span class="hb-text-night font-weight-medium">
                        How would you like to settle the amount below?
                    </span>
                </div>
            </template>

            <template v-slot:content>
                <v-row class="hb-table-row ma-0">
                    <v-col md="3" class="key-label pl-3 pt-4">
                        <div>Total Amount</div>
                    </v-col>
                    <v-col md="8" class="pt-4">
                        <span>${{ totalUnAppliedAmount }}</span>
                    </v-col>
                </v-row>
                <v-radio-group v-model="autoReconcile">
                    <v-row class="ma-0">
                        <v-col md="12" class="pt-4">
                            <v-radio value=0>
                                <template v-slot:label>
                                    <span class="hb-text-night font-weight-medium">
                                        Send total amount to Reserve and/or Credit Balance
                                    </span>        
                                </template>
                            </v-radio>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0">
                        <v-col md="12" class="pt-1 pr-3">
                            <v-radio value=1>
                                <template v-slot:label>
                                    <span class="hb-text-night font-weight-medium">
                                        Auto-reconcile the account.
                                    </span>
                                </template>
                            </v-radio>
                            <p class="pl-8 hb-text-light text-body-2">
                                When you auto-reconcile an account, you will use the Total Amount to pay off all past due/open 
                                invoices beginning from your oldest. 
                                Anything left over will go back to the tenant’s Reserve and/or Credit Balance. 
                            </p>
                        </v-col>
                    </v-row>
                </v-radio-group>
            </template>

            <template v-slot:actions>
                <hb-btn color="primary" class="ml-2" @click="saveAdjustedInvoice(false)" :disabled="isLoading($options.name)" :loading="isLoading($options.name)">Confirm</hb-btn>
            </template> 
        </hb-modal> -->

        <hb-modal
            size="medium"
            title="Unallocated Balance"
            v-model="showUnAppliedPaymentsUtilizationOptions"
            confirmation
            show-help-link
        >

            <template v-slot:content>
                <div class="pa-4">
                    <span class="hb-text-night">After adjusting the invoice there is still an amount that would be unallocated. How would you like to settle the amount below?</span><br>
                    <span v-if="!!invoice.Lease.moved_out" class="hb-text-night">Future Charge cannot be generated on a closed lease.</span>
                </div>

                <v-row class="pa-0 ma-0"
                    v-for="(p,i) in paymentDetails"
                    :key="p.id"
                >
                    <v-col class="pt-0 pb-4 px-4 ma-0">
                        <v-select
                            :items="adjustPaymentOptions[i].options"
                            v-model="adjustPaymentOptions[i].selectedOption"
                            :label="`Payment ${p.Payment.transaction_id ? '#'+ p.Payment.transaction_id : p.Payment.method || '' }`"
                            dense
                            return-object
                            :name="`adjustOption_${i}`"
                            v-validate="'required'"
                            :error-messages="errors.collect(`ajdustOption_${i}`)"
                            data-vv-scope="adjust"
                            :data-vv-as="`Payment #${p.Payment.transaction_id}`"
                            hide-details="auto"
                        >
                        </v-select>
                    </v-col>
                </v-row>

            </template>
            <template v-slot:actions>
                <hb-btn color="primary" @click="saveAdjustedInvoice(false)" :disabled="isLoading($options.name)" :loading="isLoading($options.name)">Confirm</hb-btn>
            </template>
        </hb-modal>

        <hb-modal
            size="medium"
            :title="invoiceHasPayment ? 'Remaining Balance & Void Reason' : 'Void Reason'"
            v-model="showVoidInvoice"
            confirmation
            show-help-link
        >

            <template v-slot:content>
                <div v-if="invoiceHasPayment" class="pa-4">
                    <span class="hb-text-night">A payment amount is applied to this invoice.</span><br>
                    <span class="hb-text-night">How would you like to settle the amount below?</span><br>
                    <span v-if="!!invoice.Lease.moved_out" class="hb-text-night">Future Charge cannot be generated on a closed lease.</span>
                    
                </div>

                <v-row class="pa-0 ma-0"
                    v-for="(p,i) in getFilteredPayments(invoice)"
                    :key="p.id"
                >
                    <v-col class="pt-0 pb-4 px-4 ma-0">
                        <v-select
                            :items="paymentOptions[i].options"
                            v-model="paymentOptions[i].selectedOption"
                            @change="handleSelections(paymentOptions[i].selectedOption, i)"
                            :label="`Payment ${p.Payment.transaction_id ? '#'+ p.Payment.transaction_id : p.Payment.method || '' }`"
                            dense
                            return-object
                            :name="`refundOption_${i}`"
                            v-validate="'required'"
                            :error-messages="errors.collect(`refundOption_${i}`)"
                            data-vv-scope="refund"
                            :data-vv-as="`Payment #${p.Payment.transaction_id}`"
                            hide-details="auto"
                        >
                        </v-select>
                    </v-col>
                </v-row>

                <div class="pa-4">
                    <v-text-field
                        single-line
                        dense
                        flat
                        class="input-font-size"
                        label="Void Reason*"
                        v-model="void_reason"
                        data-vv-name="void_reason"
                        data-vv-as="void reason"
                        v-validate="'required'"
                        data-vv-scope="refund"
                        :error-messages="errors.collect('void_reason')"
                        hide-details
                    ></v-text-field>
                </div>

            </template>
            <template v-slot:actions>
                <hb-btn color="primary" @click="voidConfirm" :disabled="isLoading($options.name)" :loading="isLoading($options.name)">Confirm</hb-btn>
            </template>
        </hb-modal>

        <hb-modal
            size="medium"
            title="Reissue Invoice"
            v-model="showReissueInvoice"
            confirmation
            show-help-link
        >

            <template v-slot:content>
                <div class="pa-4">
                    <span class="hb-text-night">You are about to reissue the invoice.</span><br>
                    <span class="hb-text-night">Please select a due date for the invoice.</span>
                </div>

                <v-row class="hb-table-row ma-0 pa-0">
                    <v-col md="3" class="key-label pl-6 pt-3">
                        <div>
                            Due Date
                        </div>
                    </v-col>
                    <v-col md="9" class="pa-0 ma-0">
                        <v-row class="ma-0 mt-3 ml-3 mb-3">
                            <v-col md="12" class="pa-0">
                                <span style="color: #637381;">Select a new due date that is between the current date and the last day of the current billing period for the reissued invoice.</span>
                            </v-col>
                        </v-row>
                        <v-row class="ma-0">
                            <hb-date-picker
                                id="dueDate"
                                data-vv-scope="reissue"
                                label="Due Date"
                                :min="dateRange.startDate"
                                :max="dateRange.endDate"
                                data-vv-as="due_date"
                                v-model="dueDate"
                                :default_today="true"
                            ></hb-date-picker>
                        </v-row>
                    </v-col>
                </v-row>

            </template>
            <template v-slot:actions>
                <hb-btn color="primary" @click="processReissueInvoice" :disabled="isLoading($options.name)" :loading="isLoading($options.name)">Reissue Invoice</hb-btn>
            </template>
        </hb-modal>

        <hb-modal
            size="small"
            v-model="showEmptyInvoiceLineWarning"
            title="Warning"
            confirmation
            show-help-link
            >
            <template v-slot:content>
                <div class="px-6 py-4">
                You are removing all items on the invoice. Please void the invoice to remove the invoice. 
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn color="primary" @click="closeShowEmptyInvoiceLineWarning">Ok</hb-btn>
            </template>
        </hb-modal>
        
        <hb-modal size="medium" title="Yield Retained Revenue" v-model="showRefundModal" v-if="showRefundModal" show-help-link>
            <template v-slot:content>
                <v-row class="pa-0 ma-0">
                    <v-col cols="4" class="py-4 px-6 ma-0 hb-cloud-light font-weight-medium">
                        Yield From
                    </v-col>
                    <v-col cols="8" class="ma-0 pt-4 pl-6">
                        <v-select 
                            v-model="refund.paymentFrom" 
                            :items="paymentTypes" 
                            item-value="value" 
                            item-text="text"
                            name="payment_type" 
                            label="Select a payment" 
                            hide-details
                            data-vv-scope="refund_retained_revenue"
                            >
                        </v-select>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0 ma-0">
                    <v-col cols="4" class="py-4 px-6 ma-0 hb-cloud-light font-weight-medium">
                        Amount
                    </v-col>
                    <v-col cols="8" class="ma-0 pt-4 pl-6">
                        <v-text-field 
                            v-model="refund.amount"
                            v-validate="'required|decimal:2'"
                            :disabled="true"
                            label="Refund Amount" 
                            flat
                            id="refund_amount" 
                            name="refund_amount" 
                            data-vv-as="refund amount"
                            data-vv-scope="refund_retained_revenue"
                            :hide-details="!errors.collect('refund_amount').length"
                            :error-messages="errors.collect('refund_amount')"
                            >
                        </v-text-field>
                        <span class="caption" style="color: #637381;">(Total Available: {{ refund.amount | formatMoney }})</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0 ma-0">
                    <v-col cols="4" class="py-4 px-6 ma-0 hb-cloud-light font-weight-medium">
                        Yield To
                    </v-col>
                    <v-col cols="8" class="ma-0 pt-4 pl-6">
                        <v-select 
                            v-model="refund.paymentTo" 
                            :items="refundToTypes" 
                            item-value="value" 
                            item-text="text"
                            name="refund_to" 
                            label="Select yield to" 
                            hide-details
                            data-vv-scope="refund_retained_revenue"
                            >
                        </v-select>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:actions>
                <hb-btn color="primary" :disabled="isRefundAmount || disableYieldButton" @click="processRetainedRevenue">Yield</hb-btn>
            </template>
        </hb-modal>
        <payments-notification-modal
            v-model="notificationModal"
            notificationType="edit_invoices"
        >
        </payments-notification-modal>
    </div>
</template>

<script type="text/babel">
import Modal from '../assets/Modal.vue';
import PulseLoader from '../assets/DotLoader.vue';
import DropdownMenu from '../assets/DropdownMenu.vue';
import RecordPayment from '../leases/RecordPayment.vue';
import ViewInvoice from './ViewInvoice.vue';
import EditInvoice from './EditInvoice.vue';
import CreateInvoice from './CreateInvoice.vue';
import HbDatePicker from '../assets/HummingbirdDatepicker';
import SendInvoice from "../assets/SendInvoice.vue";
import Status from '../includes/Messages.vue';
import Loader from '../assets/CircleSpinner.vue';
import api from '../../assets/api.js';
import moment from 'moment';
import { EventBus } from '../../EventBus.js';
import { mapGetters , mapMutations} from 'vuex';
import { notificationMixin } from  '../../mixins/notificationMixin.js';
import PaymentsNotificationModal from '../payments/PaymentsNotificationModal.vue';
import Reversal from '../payments/Reversal.vue';
import ReversalConfirmation from '../payments/ReversalConfirmation.vue';

export default {
    name: "DisplayInvoice",
    mixins: [notificationMixin],
    data() {
        return {
            state: 'view',
            invoice:{},
            invoiceBackup:{},
            payments:[],
            showVoidInvoice: false,
            showReissueInvoice: false,
            showEmptyInvoiceLineWarning: false,
            isLoadingFlag: false,
            paymentOptions: [],
            sendInvoiceModal: false,
            autoReconcile: "1",
            void_reason: '',
            showAdjustmentConfirmation: false,
            adjustmentConfirmationIfNoPayment: false,
            adjustmentMode: false,
            showUnAppliedPaymentsUtilizationOptions: false ,
            totalUnAppliedAmount: 0,
            dueDate: '',
            dateRange: {
                startDate: '',
                endDate: ''
            },
            paymentDetails: [],
            adjustPaymentOptions: [],
            is_auction_invoice: false,
            isFreeInsuranceInvoice: false,
            // Retained Revenue
            showRefundModal: false,
            showYieldButton: false,
            disableYieldButton: false,
            notificationModal: false,
            paymentTypes: [
                { text: "Retained Revenue", value: "retained_revenue" }
            ],
            refundToTypes: [
                { text: "Tenant", value: "tenant" },
                { text: "State", value: "state" }
            ],
            refund: {
                paymentFrom: 'retained_revenue',
                amount: 0,
                paymentTo: 'tenant'
            },
            is_inter_property_invoice: false,
            is_fatzebra_payment:false,
            is_fatzebra_card_payment: false,
            is_fatzebra_non_card_payment_method: false,
            if_eftpos_payment: false,
            refundFlag:false,
            isRefund: false,
            selectedInvoices: [],
            auctionPayment: false,
            is_auctioned_lease_payment: false,
            refund_option: '',
            reversalConfirmation: false,
            void_data: {},
            is_void: false,
            credit_line_item : false,
            is_credit_invoice_line: false,
            refundAmount: 0,
            credit_data: {},
            has_adjustment_payment : false,
            showDirectRefundModal: false,
            componentKey: 0,
            direct_refund_arr: [],
            selected_payment_id: '',
            current_selected_index: null,
            totalRefundAmountForCredit: 0,
            totalAmount: 0,
        }
    },
    components:{
        Modal,
        PulseLoader,
        DropdownMenu,
        Loader,
        RecordPayment,
        ViewInvoice,
        EditInvoice,
        CreateInvoice,
        Status,
        HbDatePicker,
        PaymentsNotificationModal,
        SendInvoice,
        Reversal,
        ReversalConfirmation,
    },
    async created (){
        if(this.type == 'create') this.state = 'create';
        await this.fetchData();
        EventBus.$on('show_reversal_confirmation', this.showReversalConfirmation);
        EventBus.$on('show_void_confirmation', this.showVoidConfirmation);
        EventBus.$on('setInvoices', this.setInvoices);
        EventBus.$on('selectAllInvoices', this.selectAllInvoices);
        EventBus.$on('Hb-Payments:direct_refund', this.saveDirectRefund);
    },
    computed:{
        ...mapGetters({
            isAdmin: 'authenticationStore/isAdmin',
            hasPermission: 'authenticationStore/rolePermission',
            reversal: 'paymentsStore/getReversal',
        }),
        modalDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        earliestPaymentDate(){
            let sortedDates = this.invoice.Payments.sort((a,b) => new Date(a.created) - new Date(b.created)).map(m => m.created);
            let earliestDate = sortedDates && sortedDates[0];
            // Need to verify why the utc conversion is done here?
            return earliestDate ? moment(earliestDate) : null;
        },
        disableReissue(){
           let current_date = moment().format('YYYY-MM-DD');
           return (!this.invoice?.can_reissue || !(this.invoice?.Lease?.status == 1 && (this.invoice?.Lease?.end_date == null || moment(current_date).isAfter(this.invoice?.Lease?.end_date))));
       },
       reissueButton(){
            return this.state !== 'create' && (this.invoice.status == -1) && this.isAdmin
       },
        isRefundAmount() {
            return this.refund.amount ? false : true;
        },
        canVoidAdjust(){
            return (!this.invoice.can_void_adjust || this.is_inter_property_invoice || this.isFreeInsuranceInvoice || ['auction_payment','move_out'].includes(this.invoice.Lease?.auction_status) || this.has_adjustment_payment)
        },
        invoiceHasPayment(){
            return !!this.invoice.Payments.length;
        },
        isAdjustEnabled() {
            let invoiceData = this.invoice;
            if (invoiceData?.Lease && Object.keys(invoiceData.Lease).length > 0) {
                return true;
            }
            let merchandiseInvoiceLines = invoiceData.InvoiceLines.every(item => {
                let { Product } = item;
                return Product && Product.category_type === 'service' && Product.default_type === 'product' && Product.type === 'product'
            })

            let feesInvoiceLines = invoiceData.InvoiceLines.every(item => {
                let { Product } = item;
                return Product && Product.default_type === 'late' && Product.type === 'product'
            })
            return merchandiseInvoiceLines || feesInvoiceLines ? false : true;
        },
        invoiceType() {
            const invoiceTypes = [
                { condition: this.is_auction_invoice, label: 'Auction' },
                { condition: this.is_inter_property_invoice, label: 'Inter-Property' },
                { condition: this.isFreeInsuranceInvoice, label: 'Free Insurance' },
                { condition: this.has_adjustment_payment, label: 'Adjustment' },
            ];

            const currentInvoiceType = invoiceTypes.find(type => type.condition);
            return currentInvoiceType.label;
        }
    },
    methods: {
        ...mapMutations({
            setReversalObject: 'paymentsStore/setReversalObject',
            resetReversal: 'paymentsStore/resetReversal',
            setReversal: 'paymentsStore/setReversal'
        }),
        showReversalConfirmation() {
            this.reversalConfirmation = true;
        },
        openVoidModal(data) {            
            this.credit_line_item= data.is_credited
            this.showVoidInvoice = true;
            this.refundAmount = data.refundAmount;
            this.line_id = data.line.id;
            this.creditAmount = data.creditAmount;
            this.totalAmount = data.refundAmount;
            this.getpaymentOptions();             
        },
        getFilteredPayments(invoice) {
            if (this.credit_line_item && this.line_id) {
                const line = invoice.InvoiceLines.find(line => line.id === this.line_id);                             
                if (!line) return [];
                const paymentIds = line.LineAllocations.map(allocation => allocation.payment_id);
                let filtered_arr = [];
                let runningTotal = 0;
                for(const payment of invoice.Payments)
                    if(paymentIds.includes(payment.Payment.id)  && payment.Payment.method !== 'adjustment') {
                        filtered_arr.push(payment);
                        runningTotal += payment.amount;
                        if(runningTotal >= this.totalAmount) {
                            break;
                        };
                    }
                console.log('filtered arr:: ', filtered_arr);
                this.totalRefundAmountForCredit = filtered_arr.reduce((sum,p) => sum + p.amount, 0);
                return filtered_arr;
            } else {
                return invoice.Payments.filter(payment => payment.Payment.method !== 'adjustment');
            }
        },


        async voidConfirm() {
            const status = await this.$validator.validateAll("refund");
            if(!status) return

            let paymentDetl = [];
            if(this.creditAmount > 0 && this.credit_line_item){
                const payload = { amount: this.creditAmount };
                await api
                .post(this, api.INVOICES + `${this.invoice.id}/invoice_line/${this.line_id}/credit`, payload)
                .then((response) => {
                console.log("Credit updated successfully", response);
                })
                .catch((error) => {
                console.error("Failed to update credit", error);
                });
            }
            for (var i=0; i < this.paymentOptions.length; i++) {
                let p = this.paymentOptions[i].selectedOption
                let amount = p?.amount;
                console.log("Is credit_line_item ?", this.credit_line_item);

                if (this.credit_line_item && this.direct_refund_arr.length == 0) {                     
                    amount = this.refundAmount;      
                    console.log("credit_line_item amount",amount);               
                }
                console.log("TOTAL CREDIT AMOUNT", amount);
        
                if(p && p.type === 'refund' && this.direct_refund_arr.length == 0) {
                    paymentDetl.push({
                        id: p.Payment.id,
                        type: p.type,
                        amount: amount,
                    });
                } 
                if(p && p.type === 'refund' && this.is_fatzebra_payment && this.is_fatzebra_non_card_payment_method){
                    if (this.credit_line_item && this.direct_refund_arr[i]) {
                        paymentDetl.push({
                            amount: this.direct_refund_arr[i].amount,
                            reason: this.direct_refund_arr[i].reason,
                            bsb_number: this.direct_refund_arr[i].bsb_number,
                            account_number: this.direct_refund_arr[i].account_number,
                            account_name: this.direct_refund_arr[i].account_name,
                            id: p.Payment.id,
                            type: 'direct-refund',
                        });
                        this.credit_data = {
                            amount: this.totalAmount,
                            PaymentDetails: paymentDetl
                        };
                        console.log("credit data", this.credit_data);
                        
                        this.is_credit_invoice_line = true;
                        this.is_void = true;
                    } else {
                        this.is_credit_invoice_line = false;
                        if(this.direct_refund_arr[i]) {
                            paymentDetl.push({
                                amount: this.direct_refund_arr[i].amount,
                                reason: this.direct_refund_arr[i].reason,
                                bsb_number: this.direct_refund_arr[i].bsb_number,
                                account_number: this.direct_refund_arr[i].account_number,
                                account_name: this.direct_refund_arr[i].account_name,
                                id: p.Payment.id,
                                type: 'direct-refund',
                            });
                        }
                    }
                }
            }
                
            
            let data = {
                PaymentDetails: paymentDetl,
                void_reason: this.void_reason,
                void_source: 'manual'
            };
            if(this.credit_line_item) {             
                let payload_for_credit = this.credit_data;    
                api.post(this, api.INVOICES + `${this.invoice.id}/invoice_line/${ this.line_id}/credit`, payload_for_credit).then((response) => {
                this.showVoidInvoice = false;
                this.dialog = false; 
                this.state = 'view';
                this.$emit('close');
                EventBus.$emit('invoice_saved');
                console.log("Credit updated successfully", response);
                // this.invoice = response.invoice;
                })
                .catch((error) => {
                console.error("Failed to update credit", error);
                });
            } else {

            api.post(this, api.INVOICES + this.invoice_id + '/void', data).then(r => {
                this.showVoidInvoice = false;
                this.dialog = false; 
                this.state = 'view';
                this.$emit('close');
                EventBus.$emit('invoice_saved');
                if(msg) {
                    this.successSet('TransactionHistory', `Invoice ${this.invoice.number} had been voided. The remaining balance has been placed in prepay/refund`);
                }
            });
        }
        },
        async handleSelections(selected, index) {
            try {
                let diff_amount = 0;
                const filteredPayments = this.getFilteredPayments(this.invoice);
                let lastPayment = filteredPayments[filteredPayments.length - 1];
                this.selected_payment_id = selected.Payment.id;
                setTimeout(() => {
                    this.resetReversal();
                    console.log("Reversal reset!", this.reversal);
                }, 0);
                if((selected.type === ('future'))) {
                    let amount;
                    if(this.credit_line_item && this.selected_payment_id === lastPayment.Payment.id) {
                        diff_amount = this.totalRefundAmountForCredit - this.totalAmount;
                        amount = selected.amount - diff_amount;
                    } else{
                        amount = selected.amount;
                    };
                    this.setReversal({
                        property: "amount",
                        propertyValue: amount
                    });
                    this.setReversal({
                        property: "id",
                        propertyValue: this.selected_payment_id
                    });
                    let obj = this.reversal;
                    obj.type = 'future';
                    await this.saveDirectRefund(obj);
                } else {
                    console.log('selected id: ', this.selected_payment_id);
                    this.$validator.reset("refund");
                    this.current_selected_index = index;
                    if(this.is_fatzebra_payment && this.is_fatzebra_non_card_payment_method) {
                            this.showRefund();
                            this.showDirectRefundModal = true;
                            this.componentKey++;
                            this.refundAmount =  selected.amount;
                            this.$nextTick(() => {
                            this.paymentMethod = selected.Payment.method;

                            this.invoice_void_save = true;

                            if(this.credit_line_item && this.selected_payment_id === lastPayment.Payment.id) {
                                diff_amount = this.totalRefundAmountForCredit - this.totalAmount;
                            }
                            this.$nextTick(() => {
                                EventBus.$emit('invoice_void_save', diff_amount);
                            });
                        });
                    }
                }
            } catch(err) {
                console.log('error', err);
            }
        },

        async resetValidation() {
            this.refundFlag = false;
            this.showVoidInvoice = true;
            this.paymentOptions[this.current_selected_index].selectedOption = null;
            this.$validator.reset("refund");
            this.getFilteredPayments(this.invoice);
        },

        async saveDirectRefund(direct_refund_obj = {}) {

            await this.$nextTick();

            if (!(direct_refund_obj) || Object.keys(direct_refund_obj).length === 0) {
                direct_refund_obj = {
                    reversal_type: this.reversal.reversal_type,
                    reason: this.reversal.reason,
                    amount: this.reversal.amount,
                    account_name: this.reversal.account_name,
                    bsb_number: this.reversal.bsb_number,
                    account_number: this.reversal.account_number,
                    payment_method: this.reversal.payment_method,
                };
            }
            console.log('---direct_refund_obj ---', direct_refund_obj);
            try {
                this.showVoidInvoice = true;
                if(direct_refund_obj){
                    const index = this.direct_refund_arr.findIndex(i => i.id === this.selected_payment_id);
                    direct_refund_obj.id = this.selected_payment_id;
                    console.log('index--> ', index);
                    if(index !== -1) {
                        this.direct_refund_arr[index] = direct_refund_obj;
                    } else {
                        this.direct_refund_arr.push(direct_refund_obj);
                    }
                    console.log('direct_refund_arr:: ', this.direct_refund_arr);
                    setTimeout(() => {
                        this.resetReversal();
                        console.log("Reversal reset!", this.reversal);
                    }, 0);

                    return this.direct_refund_arr;
                }
            } catch(err) {
                console.log('err: ', err);
            }
        },

        async setInvoices(invoices) {
            this.selectedInvoices = invoices; 
            this.calculateRefundInformation();
        },
        async saveInvoiceAdjustment() {
            await this.saveAdjustedInvoice(true);

            if(!this.paymentDetails.length){
                this.adjustmentConfirmationIfNoPayment = true
            }
            else{
                this.showUnAppliedPaymentsUtilizationOptions = true;
            }
        },
        closeShowEmptyInvoiceLineWarning(){
            this.showEmptyInvoiceLineWarning = false
            this.showUnAppliedPaymentsUtilizationOptions = false
            this.adjustmentConfirmationIfNoPayment = false;
        },
        getpaymentOptions(){
            this.paymentOptions = []
            const filteredPayment = this.getFilteredPayments(this.invoice);
            this.invoice.Payments.filter((p) => p.Payment.method !== "adjustment").forEach((p,index,array) => {
                // let amount = this.credit_line_item ? this.refundAmount : p.amount; 
                let diff = this.totalRefundAmountForCredit - this.totalAmount;
                let amount = (index === filteredPayment.length - 1) ? (p.amount - diff): p.amount;
                if(p.Payment.method && p.Payment.method === 'credit') {
                    this.paymentOptions.push({
                        payment_id: p.id,
                        options: [{
                            value: p.id,
                            text: `Apply ($${amount}) to a future charge`,
                            type: 'future',
                            disabled: !!this.invoice.Lease.moved_out,
                            ...p
                        }],
                        selectedOption: null  
                    });
                    return;
                }

                let text = ''
                if (['card', 'ach'].indexOf(p.Payment.method) >= 0) {
                    text = p.PaymentMethod ? p.PaymentMethod.card_type + '****' +  p.PaymentMethod.card_end : ''
                } else {
                        if(this.is_fatzebra_payment && this.is_fatzebra_non_card_payment_method){
                            switch (p.Payment.method) {
                                case 'cash':
                                    text = 'Direct Refund';
                                    break;
                                case 'directdebit':
                                    text = 'Direct Refund';
                                    break;
                                case 'check':
                                    text = 'Direct Refund';
                                    break;
                                case 'eftpos':
                                    text = 'EFTPOS Refund/Direct Refund';
                                    break;
                                default:
                                    text = '';
                                    break;
                            }
                        } else {
                            text = p.Payment.method
                        }
                }

                let options = [
                    {
                    value: p.id,
                    text: `Refund ($${amount}) to ${text}`,
                    type:'refund',
                    ...p
                }]

                if(this.invoice.lease_id){
                    options.push({
                        value: 0,
                        text: `Apply ($${amount}) to a future charge`,
                        type: 'future',
                        disabled: !!this.invoice.Lease.moved_out,
                        ...p
                    });
                }

                this.paymentOptions.push({
                    payment_id: p.id,
                    options,
                    selectedOption: null  
                })
            })
        },
        getAdjustPaymentOptions(){
            this.adjustPaymentOptions = []
            this.paymentDetails.forEach(p => {
                if(p.Payment.method && p.Payment.method === 'credit') {
                    this.adjustPaymentOptions.push({
                        options: [{
                            value: p.id,
                            text: `Apply ($${p.credit}) to a future charge`,
                            type: 'future',
                            disabled: !!this.invoice.Lease.moved_out,
                            ...p
                        }],
                        selectedOption: null
                    });
                    return;
                }

                let text = ''
                if (['card', 'ach'].indexOf(p.Payment.method) >= 0) {
                    text = p.PaymentMethod ? p.PaymentMethod.card_type + '****' +  p.PaymentMethod.card_end : ''
                } else {
                    text = p.Payment.method
                }

                let options = [{
                    value: p.id,
                    text: `Refund ($${p.credit}) to ${text}`,
                    type:'refund',
                    ...p
                }]

                options.push({
                    value: 0,
                    text: `Apply ($${p.credit}) to a future charge`,
                    value: p.credit,
                    type: 'future',
                    disabled: !!this.invoice.Lease.moved_out,
                    ...p
                });
                
                this.adjustPaymentOptions.push({
                    options,
                    selectedOption: null  
                })
            })
        },
        showAdjustmentMode() {
            this.invoiceBackup = JSON.parse(JSON.stringify(this.invoice));

            this.adjustmentMode = true;
            this.showAdjustmentConfirmation = false;
            this.state = 'view';
        },
        adjustInvoice() {
            if (this.hasPermission("edit_invoices")){
                this.showAdjustmentConfirmation = true;
            } else {
                this.notificationModal = true;
            }
        },
        closeEdit(){
            this.adjustmentMode = false
            this.state = 'view';
        },
        voidInvoice(invoice){
            this.credit_line_item= false;
            this.refundAmount = null;
            this.line_id = null
            this.showVoidInvoice = true;
            this.getpaymentOptions(); 
        },
        reissueInvoice(invoice){
            this.showReissueInvoice = true;
        },
        async fetchData(){
                this.isLoadingFlag = false
                if(!this.invoice_id) return;
                api.get(this, api.INVOICES + this.invoice_id).then(async (r) => {
    
                    this.invoice = r.invoice;
                    await api.get(this, api.PROPERTIES + this.invoice.Property.id + '/connections').then(async (c) => {
                        
                    if (c && c.connections) {
                        console.log("c.connections?.length", c.connections.length);
                        if (c.connections.length > 0) {
                            let fatZebraConnection = c.connections.find(c => c.name === 'fatzebra');
                            this.is_fatzebra_payment = fatZebraConnection ? true : false;
    
                            let FatZebraNonCardPayments = c.connections.find(c => c.name === 'fatzebra' && ['directdebit', 'eftpos', 'cash', 'check'].includes(c.type));
                            this.is_fatzebra_non_card_payment_method = FatZebraNonCardPayments ? true : false;
                        }
                    } 
                }).catch(err => {
                    console.error("Error fetching connections:", err);
                });
                    this.invoice.date = moment(this.invoice.date).format('MM/DD/YYYY');
                    this.invoice.due = moment(this.invoice.due).format('MM/DD/YYYY');
    
                    this.is_auction_invoice = this.isAuctionInvoiceFound();
                    this.is_inter_property_invoice = this.isInterPropertyFound();
                    this.isFreeInsuranceInvoice = this.isFreeInsuranceInvoiceFound();
                    this.has_adjustment_payment = this.hasAdjustmentPayment();
                    this.getpaymentOptions();
                    if(this.invoice.lease_id){
                        api.get(this, api.LEASES + this.invoice.lease_id + "/billing_periods").then(r => {
                            if(r.billing_months && r.billing_months.current){
                                let curr_period = r.billing_months.current;
                                this.dueDate = curr_period.start;
                                this.dateRange.startDate = curr_period.start;
                                this.dateRange.endDate = moment(this.invoice.period_start).isAfter(curr_period.end)? moment(this.invoice.period_end).format('YYYY-MM-DD'): curr_period.end;
                            }
                        });
                    }
                     this.isLoadingFlag = true
                    if(this.invoice && this.invoice.InvoiceLines) {
                        const retainedRevenueLines = this.invoice.InvoiceLines.filter((item) => item.Product.slug === "retained_revenue");
                        this.refund.amount = retainedRevenueLines.length ? retainedRevenueLines[0]?.cost : 0;
                    }
    
                    if(this.invoice.Payments[0].payment_id) {
                        api.get(this, api.GET_PAYMENT + this.invoice.Payments[0].payment_id + "?unit_info=true").then(async (r) => {
                        this.payment = r.payment;
                        this.payment.Property = r.payment.Property
                        this.getRefundOptions();
                        this.selectedInvoices = this.is_inter_property_payment ? this.payment.InterPropertyPayment.appliedPayments : this.payment.AppliedPayments;//map(payment => payment.invoiceId);
                        this.auctionPayment = this.payment.is_auction_payment;
                        this.is_auctioned_lease_payment = this.payment.is_auctioned_lease_payment;
                        this.is_inter_property_payment = this.payment.is_inter_property_payment;
    
                    });
                    }
                });
            },
            getRefundOptions() {  
                let text = ''
                if (['card', 'ach'].indexOf(this.payment.method) >= 0) {
                    text = this.payment.PaymentMethod.card_type + '****' +  this.payment.PaymentMethod.card_end
                } else {
                    text = this.payment.method
                }
    
                this.refund_option = {
                    text,
                    value: this.payment.PaymentMethod.id  || 1
                }
              
            },
    
            showRefund() {
            
                this.selectedInvoices.forEach((inv, i) => {
                this.selectedInvoices[i].refund_amount = this.selectedInvoices[i].amount
                });  
                this.calculateRefundInformation();
                this.showVoidInvoice = false;
                this.isRefund = true;
                this.refundFlag = true;
            },
    
            calculateRefundInformation() {
                var invoices = [];
                var total_refund = 0;
    
                for(var i=0; i<this.selectedInvoices.length; i++) {
                    const refundAmount = +this.selectedInvoices[i].refund_amount || this.selectedInvoices[i].amount;
    
                    total_refund = (+total_refund + refundAmount).toFixed(2);
                    invoices.push({
                    invoices_payment_id: this.selectedInvoices[i].id,
                    amount: refundAmount,
                    number: this.selectedInvoices[i].number,
                    invoice_amount:this.selectedInvoices[i].amount,
                    });
                }
                
                
                var data = {
                    amount: parseFloat(total_refund).toFixed(2),
                    // reason: 'un-apply refund',
                    is_prepay: false,
                    invoices
                }
                console.log("data in inv", data);
    
                if (this.refund_option.value === 0) {
                    data.is_prepay = true
                }
    
                this.setReversalObject({ reversal: { ...this.reversal, ...data } });
            },
    
            isPaymentRefunded() {
                if(this.is_inter_property_payment) {
                    const amountApplied = this.payment.InterPropertyPayment.appliedPayments.reduce((acc, item) => acc + item.amount, 0);
                    const sourceAmount = this.payment.InterPropertyPayment.sourcePayment.amount;
                    return amountApplied == sourceAmount ? false : true;
                }
                return this.payment?.Refunds.length > 0;
            },
            isFullRefund() {
                if(this.payment?.Refunds?.length > 1) return false;
                const appliedPayments = this.is_inter_property_payment ? this.payment.InterPropertyPayment.appliedPayments : this.payment.AppliedPayments;
                const amountApplied = appliedPayments.reduce((acc, item) => acc + item.amount, 0);
                return amountApplied > 0 ? false : true;
            },

        isAuctionInvoiceFound() {
            let is_auction_invoice = false;
            if(this.invoice && this.invoice.InvoiceLines.length) {
                this.invoice.InvoiceLines.forEach(line =>{
                    if (line.Product && line.Product.default_type === 'auction' ) {
                        is_auction_invoice = true;
                        this.showYieldButton = line.Product.slug === 'retained_revenue';
                    }
                });
                if(this.showYieldButton){
                    let rr_adjustment_index = this.invoice.Payments && this.invoice.Payments.findIndex(appliedPayment =>
                        appliedPayment.Payment && appliedPayment.Payment.sub_method === 'retained_revenue'
                    );
                    if(rr_adjustment_index > -1) this.showYieldButton = false;
                }
            }
            return is_auction_invoice;
        },
        isInterPropertyFound() {
            let is_inter_property_invoice = false;
            if(this.invoice && this.invoice.InvoiceLines.length) {
                this.invoice.InvoiceLines.forEach(line =>{
                    if (line.Product && line.Product.default_type === 'inter_property_adjustment') {
                        is_inter_property_invoice = true;
                    }
                });
                if(this.showYieldButton){
                    let rr_adjustment_index = this.invoice.Payments && this.invoice.Payments.findIndex(appliedPayment =>
                        appliedPayment.Payment && appliedPayment.Payment.sub_method === 'retained_revenue'
                    );
                    if(rr_adjustment_index > -1) this.showYieldButton = false;
                }
            }

            const { Payments: invoicePayments } = this.invoice;
            const isInvoicePaidByInterPropertyPayment = invoicePayments.length && (invoicePayments.some(ip => ip.Payment?.sub_method === 'inter_property_payment'));
            if(isInvoicePaidByInterPropertyPayment) {
                is_inter_property_invoice = true;
            }

            return is_inter_property_invoice;
        },
        isFreeInsuranceInvoiceFound(){
            let isFreeInsuranceInvoice = false;
            if(this.invoice && this.invoice.InvoiceLines.length == 1) {
                const line = this.invoice.InvoiceLines[0];
                if (line.Product && line.Product.default_type === 'insurance') {
                    const { Payments: invoicePayments } = this.invoice;
                    const isInvoicePaidByFreeCoverageCredit = invoicePayments.length && (invoicePayments.some(ip => ip.Payment?.sub_method === 'free_coverage_credit'));
                    isFreeInsuranceInvoice = isInvoicePaidByFreeCoverageCredit;
                }
            }
            return isFreeInsuranceInvoice;
        },
        hasAdjustmentPayment() {
            let has_adjustment_payment = false;
            if (this.invoice && this.invoice.Payments?.length) {
                has_adjustment_payment = this.invoice.Payments.some(
                    payment => payment?.Payment?.credit_type === "adjustment"
                );
            }
            return has_adjustment_payment;
        },
        showWebView() {
            let routeData = this.$router.resolve("/reports.html?type=invoice&request_id=" + this.invoice.id);
            window.open(routeData.href, '_blank')
        },
        async download(invoice_id){
console.log("start");
            var data = {
                type: 'invoice',
                format: 'pdf',
                request_id: invoice_id,
            };

            let response = await api.post(this, api.REPORTS + 'download-pdf', data);
          console.log(response);
            const arr = new Uint8Array(response.data);
            var blob = new Blob([arr], {type: 'application/pdf' });
console.log(blob);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            window.open(link.href, '_blank');
            
            // link.download = "Invoice_" + this.invoice.number;
            // link.click();
           
            //this.$store.commit('notificationsStore/startDocumentDownload', response);
        },
        async saveAdjustedInvoice(dryrun = true) {

            let paymentDetl = [];
            if(!dryrun){
                const status = await this.$validator.validateAll("adjust");
                if(!status) return

                if(this.adjustPaymentOptions && this.adjustPaymentOptions.length){
                    for (var i=0; i<this.adjustPaymentOptions.length; i++) {
                        let p = this.adjustPaymentOptions[i].selectedOption;
                        paymentDetl.push({
                            id: p.Payment.id,
                            type: p.type,
                            amount: p.credit,
                        })
                    }
                }
            }
            
            let invoice = {
                dryrun: dryrun,
                ...this.$refs.viewInvoice.invoice,
                contact_id: this.contact_id,
                PaymentDetails: paymentDetl,
            };
            
            if(!dryrun && !(invoice.InvoiceLines.length  && invoice.InvoiceLines.reduce((inital, line) => inital + parseInt(line.cost), 0) > 0)){
                this.showEmptyInvoiceLineWarning = true;
                return;
            }
            api.post(this, api.INVOICES +'adjust/'+ this.invoice_id, invoice).then(r => {
                if(r.invoice && r.invoice.id) {
                    this.successSet(this.$options.name, `Invoice ${r.invoice.prior_invoice_number} has been adjusted.`);
                    this.$emit('refreshInvoice',r.invoice.id)
                    this.closeEdit()
                    this.showUnAppliedPaymentsUtilizationOptions = false
                    this.adjustmentConfirmationIfNoPayment = false;
                    EventBus.$emit('invoice_saved')
                } else {
                    this.paymentDetails = r.paymentDetails;
                    this.$refs.viewInvoice.invoice.total_tax = r.new_invoice.total_tax;
                    this.$refs.viewInvoice.invoice.discounts = r.new_invoice.discounts;
                    this.getAdjustPaymentOptions();
                }
            }).catch(err => {
                
                this.closeEdit();
                this.showUnAppliedPaymentsUtilizationOptions = false
                this.adjustmentConfirmationIfNoPayment = false;

                this.showMessageNotification({ description: err });
                this.$emit('close');
                this.$emit('refreshInvoice');
            });
        },
        async processReissueInvoice(){
            const status = await this.$validator.validateAll("reissue");
            if(!status) return
            
            let r = await api.post(this, api.INVOICES + 'reissue/'+ this.invoice_id, { due: this.dueDate})
            if(r && r.invoice_id) {
                this.$emit('refreshInvoice',r.invoice_id);
                this.closeEdit();
                this.showReissueInvoice = false;
                EventBus.$emit('invoice_saved');
                this.showMessageNotification({ type: 'success', description: 'Reissued invoice created' });
            }
        },
        closeDialogue() {
            if(!this.adjustmentMode) {
                this.$emit('close')
            } else {
                this.invoice = JSON.parse(JSON.stringify(this.invoiceBackup));
            }
            this.$validator.reset("refund");
            this.closeEdit()
        },
        showRetainedRevenueModal() {
            this.showRefundModal = true
        },
        async processRetainedRevenue() {
            this.disableYieldButton = true;
            const status = await this.$validator.validateAll('refund_retained_revenue');
            if (!status) return;
            const invoiceLines = this.invoice && this.invoice.Payments.map((item) => {
                return {
                    invoices_payment_id: item.id,
                    amount: item.amount
                }
            })
            
            const data = {
                amount: this.refund.amount,
                is_prepay: false,
                reason: `Retained Revenue Overage Return to ${this.refund.paymentTo}.`,
                reversal_type: "overage_return",
                invoices: invoiceLines,
                is_auction_retained_revenue_refund: true,
                refund_to: this.refund.paymentTo,
                refund_contact_id: this.refund.paymentTo === 'tenant' ? this.invoice.contact_id : null
            }
            
            if(this.invoice && this.invoice.Payments) {
                await api.post(this, `${api.PAYMENTS}${this.invoice.Payments[0].payment_id}/refund`, data).then(async(r) => {
                    await this.fetchData();
                    this.$emit('refreshInvoice');
                    this.showRefundModal = false;
                    this.disableYieldButton = false;
					this.showMessageNotification({ id: this.$options.name, type: "success", description: 'Retained Revenue Yield successfully' });
                }).catch((err) => {
                    this.disableYieldButton = false;
					this.showMessageNotification({id: this.$options.name, description: err});
                });
            }
        }
    },
    destroyed(){
        EventBus.$off('show_reversal_confirmation', this.showReversalConfirmation);
        EventBus.$off('setInvoices', this.setInvoices);
        EventBus.$off('selectAllInvoices', this.selectAllInvoices);
        EventBus.$off('Hb-Payments:direct_refund');
        this.credit_line_item = false;
    },

    props:['invoice_id', 'contact_id', 'type', 'fromVDialog', 'value', 'modal', 'fromMasterVueFile', 'national_account'],
    watch:{
        invoice_id:function(){
            this.state = 'view';
            this.fetchData();
        //    this.fetchPayments();
        },
        errorList () {
            if(this.errorHas(this.$options.name)) {
                this.showVoidInvoice = false
                this.showReissueInvoice = false
            }
        }
    }
}


</script>


<style scoped>

.slide-out-header-nav {
    border-bottom: 1px solid #dce8ef;
}
.slide-out-header-position-normal{
    position:static;
}
.key-label {
  background: #f4f6f8;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101318;
}

.display-invoice-header-title-wrapper {
    background: #E0F5F5;
    height: 52px;
}
</style>

<style>
    @media (max-width: 600px) {
        .view-invoice-dialog {
            display: flex;
            align-items: center;
        }
        .view-invoice-dialog .hb-btn-wrapper button {
            margin-right: 0px !important;
        }
        .border-btn-none button {
            border: unset !important;
        }
        .hb-modal-title-font-size:has(.display-invoice-mobile-title) {
            overflow: hidden;  
            text-overflow: ellipsis;
            white-space: nowrap;   
        }
    }
</style>
